@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* sm: text-3xl font-extralight leading-tight tracking-normal: 32px lineHeight: "120%", letterSpacing: "0.00em"*/
  /* md: text-7xl font-extralight leading-tight tracking-normal: 52px lineHeight: "120%", letterSpacing: "0.00em"*/
  /* lg: text-8xl font-extralight leading-tight tracking-normal: 60px lineHeight: "120%", letterSpacing: "0.00em"*/
  .font-h1 {
    @apply text-[33px] font-extralight leading-[1.2] tracking-normal md:text-7xl md:font-light lg:text-8xl;
  }
  .font-h2 {
    @apply text-[26px] font-extralight leading-[1.4] tracking-[.01em] md:text-5xl md:font-light lg:text-6xl lg:leading-[1.2];
  }
  .font-h3 {
    @apply text-[23px] font-light leading-[1.5] tracking-normal md:text-4xl md:leading-[1.2] lg:text-5xl;
  }
  /* mobile view using h3, remaining using h4 */
  .font-h4 {
    @apply text-[23px] font-light leading-[1.5] tracking-normal md:text-2xl lg:text-3xl;
  }
  .font-h5 {
    @apply text-[18px] font-light leading-[1.5] tracking-[-.01em] md:text-[21px] md:leading-[1.6] md:tracking-normal lg:text-xl lg:leading-[1.5];
  }
  .font-hero-title {
    @apply text-5xl font-light leading-[1.2] tracking-[-.01em] md:text-9xl md:leading-[1.1]  lg:text-10xl lg:leading-[1.06];
  }
  .font-body-lg {
    @apply text-xl font-light leading-[1.6] tracking-normal md:leading-[1.5] lg:leading-[1.6];
  }
  .font-p {
    @apply text-base font-light leading-[1.6] tracking-normal md:leading-[1.5]  lg:text-md lg:leading-[1.6];
  }
  .font-p-sm {
    @apply text-[14px] font-light leading-[1.6] tracking-normal;
  }
  .font-btn-lg {
    @apply text-base leading-tight tracking-normal lg:text-lg;
  }
  .font-btn {
    @apply text-base leading-tight tracking-normal;
  }
  .font-tag {
    @apply text-[10px] font-bold md:text-xs;
  }
  .font-eyebrow {
    @apply text-xs font-bold lg:text-[13px];
  }
}

:root {
  --background: 0 0% 100%;
  --foreground: 222.2 47.4% 11.2%;

  --muted: 210 40% 96.1%;
  --muted-foreground: 215.4 16.3% 46.9%;

  --popover: 0 0% 100%;
  --popover-foreground: 222.2 47.4% 11.2%;

  --accent: 210 40% 96.1%;
  --accent-foreground: 222.2 47.4% 11.2%;
}

/* styles for the lightbox player, including transitions to show and hide */
#playerLightbox {
  position: fixed;
  top: 0;
  left: 0;
  padding: 0;
  margin-left: 0;
  color: white;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 5px;
  z-index: 20;
  overflow: hidden;
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}
/* show the player */
.playerShow {
  width: 100%;
  height: 100%;
  padding: 10px;
  -webkit-box-shadow: 5px 5px 5px 5px rgba(151, 151, 151, 0.7);
  box-shadow: 5px 5px 5px 5px rgba(151, 151, 151, 0.7);
}
/* hide the player */
.playerHide {
  width: 0;
  height: 0;
  padding: 0;
}
/* style the close button for the lightbox player */
.playerClose {
  cursor: pointer;
  position: absolute;
  top: 50px;
  right: 50px;
  width: 40px;
  height: 40px;
  background: #58585b;
  border-radius: 100%;
  border: 1px solid #fff;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  font-size: 21px;
  font-weight: bold;
  line-height: 42px;
}
.video-js.vjs-thumbnails {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  @media (max-width: 720px) {
    margin: 0 16px;
    width: 100%;
    height: calc((100vw - 32px) * 0.63);
  }
}
.brightcove-react-player-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 1024px) {
  .playerClose {
    top: 30px;
    right: 15px;
    width: 30px;
    height: 30px;
    font-size: 14px;
    line-height: 30px;
  }
}
.swiper-container {
  height: 100%;
  max-height: 100vw;
  min-height: 0;
  min-width: 0;
  max-width: 100vw;
  width: 100%;
  overflow: hidden;
}

.swiper-slide {
  width: auto;
  flex-shrink: 0;
  display: block;
  height: 100%;
  max-height: 100%;
}

.swiper-wrapper {
  max-height: 100%;
  height: 100%t;
  display: flex;
}
.shadow-gradient {
  position: relative;
  z-index: 1;
}

.shadow-gradient::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 0px;
  width: 100%;
  height: calc(100% - 20px);
  background: linear-gradient(to right, #abd84f, #09c0e9);
  filter: blur(13px);
  z-index: 0;
}

.shadow-gradient-content {
  position: relative;
  z-index: 1;
}

.p-splitter-gutter {
  background-color: rgb(203, 203, 203);
}
.ck.ck-content {
  min-width: 0px;
}
.ck-editor__editable {
  min-width: 0px;
}
/*Toolbar*/
.ck-editor__top {
  min-width: 0px;
}

.ck-toolbar__items {
  flex-wrap: wrap !important;
}

 :root {
	--ck-image-style-spacing: 1.5em;
	--ck-inline-image-style-spacing: calc(var(--ck-image-style-spacing) / 2);
}

.prose {
	/* See: https://github.com/ckeditor/ckeditor5/issues/16317 */
	& .image {
		/* Provides a minimal side margin for the left and right aligned images, so that the user has a visual feedback
		confirming successful application of the style if image width exceeds the editor's size.
		See https://github.com/ckeditor/ckeditor5/issues/9342 */
		&.image-style-block-align-left,
		&.image-style-block-align-right {
			max-width: calc(100% - var(--ck-image-style-spacing));
		}

		/* Allows displaying multiple floating images in the same line.
		See https://github.com/ckeditor/ckeditor5/issues/9183#issuecomment-804988132 */
		&.image-style-align-left,
		&.image-style-align-right {
			clear: none;
		}

		&.image-style-side {
			float: right;
			margin-left: var(--ck-image-style-spacing);
			max-width: 50%;
		}

		&.image-style-align-left {
			float: left;
			margin-right: var(--ck-image-style-spacing);
		}

		&.image-style-align-right {
			float: right;
			margin-left: var(--ck-image-style-spacing);
		}

		&.image-style-block-align-right {
			margin-right: 0;
			margin-left: auto;
		}

		&.image-style-block-align-left {
			margin-left: 0;
			margin-right: auto;
		}
	}

	& .image-style-align-center {
		margin-left: auto;
		margin-right: auto;
	}

	& .image-style-align-left {
		float: left;
		margin-right: var(--ck-image-style-spacing);
	}

	& .image-style-align-right {
		float: right;
		margin-left: var(--ck-image-style-spacing);
	}

	/* Simulates margin collapsing with the preceding paragraph, which does not work for the floating elements. */
	& p + .image.image-style-align-left,
	& p + .image.image-style-align-right,
	& p + .image.image-style-side {
		margin-top: 0;
	}

	& .image-inline {
		&.image-style-align-left,
		&.image-style-align-right {
			margin-top: var(--ck-inline-image-style-spacing);
			margin-bottom: var(--ck-inline-image-style-spacing);
		}

		&.image-style-align-left {
			margin-right: var(--ck-inline-image-style-spacing);
		}

		&.image-style-align-right {
			margin-left: var(--ck-inline-image-style-spacing);
		}
	}
}

.ck.ck-splitbutton {
	/* The button should display as a regular drop-down if the action button
	is forced to fire the same action as the arrow button. */
	&.ck-splitbutton_flatten {
		&:hover,
		&.ck-splitbutton_open {
			& > .ck-splitbutton__action:not(.ck-disabled),
			& > .ck-splitbutton__arrow:not(.ck-disabled),
			& > .ck-splitbutton__arrow:not(.ck-disabled):not(:hover) {
				background-color: var(--ck-color-button-on-background);

				&::after {
					display: none;
				}
			}
		}

		&.ck-splitbutton_open:hover {
			& > .ck-splitbutton__action:not(.ck-disabled),
			& > .ck-splitbutton__arrow:not(.ck-disabled),
			& > .ck-splitbutton__arrow:not(.ck-disabled):not(:hover) {
				background-color: var(--ck-color-button-on-hover-background);
			}
		}
	}
}